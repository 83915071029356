import * as React from 'react';
import styled from '@emotion/styled';
import { AnimatedLogoWidget } from './AnimatedLogoWidget';
import { Link as L } from 'react-router-dom';
import { Routes } from '../routes';
import { useAuthStore } from '../stores/AuthStore';
import { observer } from 'mobx-react';
var S;
(function (S) {
    S.Container = styled.div `
    transition: background-color 2s;
    padding: 20px;
    background-color: ${(p) => (p.backdrop ? '#0c0f12' : 'transparent')};
    width: 100%;
    display: flex;
    position: relative;
  `;
    S.Logo = styled(AnimatedLogoWidget) `
    min-width: 100px;
    flex-shrink: 0;
  `;
    S.Spacer = styled.div `
    flex-grow: 1;
  `;
    S.Menubar = styled.div `
    display: flex;
  `;
    S.MenuItem = styled.div `
    padding: 5px 10px;
    color: white;
    font-family: 'forced-square', sans-serif;
    cursor: pointer;
  `;
    S.Link = styled(L) `
    text-decoration: none;
  `;
})(S || (S = {}));
export const HeaderWidget = observer((props) => {
    const authStore = useAuthStore();
    return (React.createElement(S.Container, { backdrop: props.backdrop },
        React.createElement(S.Link, { to: Routes.HOMEPAGE },
            React.createElement(S.Logo, { duration: 2, strokeThickness: 2 })),
        React.createElement(S.Spacer, null),
        React.createElement(S.Menubar, null, !authStore.authenticatedUser ? (React.createElement(React.Fragment, null,
            React.createElement(S.Link, { to: Routes.SIGNIN },
                React.createElement(S.MenuItem, null, "Sign in")),
            React.createElement(S.Link, { to: Routes.SIGNUP },
                React.createElement(S.MenuItem, null, "Sign up")))) : (React.createElement(S.MenuItem, { onClick: () => {
                authStore.signout();
            } }, "Sign out")))));
});
