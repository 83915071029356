import * as React from 'react';
import * as _ from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { styled } from './theme/theme';
var S;
(function (S) {
    S.Container = styled.div `
    display: flex;
    flex-direction: column;
  `;
    S.Tab = styled.div `
    padding: 10px;
    color: white;
    cursor: pointer;
    font-family: 'Open Sans';
    opacity: ${(p) => (p.selected ? 1 : 0.3)};
    user-select: none;
  `;
    S.Tabs = styled.div `
    display: flex;
  `;
    S.SliderContainer = styled.div `
    height: 4px;
    position: relative;
  `;
    S.Slider = styled.div `
    left: ${(p) => p.left}px;
    width: ${(p) => p.width}px;
    height: 100%;
    background: ${(p) => p.theme.forms.inputActiveAccent};
    position: absolute;
    transition: width 0.3s, left 0.3s;
  `;
})(S || (S = {}));
export const TabsWidget = (props) => {
    const [bounds, setBounds] = useState(null);
    const refContainer = useRef();
    const ref = useRef();
    useEffect(() => {
        if (ref.current) {
            const containerRect = refContainer.current.getBoundingClientRect();
            const tabRect = ref.current.getBoundingClientRect();
            setBounds({
                left: tabRect.left - containerRect.left,
                width: tabRect.width
            });
        }
    }, [props.tabSelected]);
    return (React.createElement(S.Container, null,
        React.createElement(S.Tabs, null, _.map(props.tabs, (tab) => {
            const selected = tab.key === props.tabSelected;
            return (React.createElement(S.Tab, { selected: selected, ref: selected ? ref : null, onClick: () => {
                    props.onTabSelected(tab);
                }, key: tab.key }, tab.label));
        })),
        React.createElement(S.SliderContainer, { ref: refContainer },
            React.createElement(S.Slider, { width: (bounds === null || bounds === void 0 ? void 0 : bounds.width) || 0, left: (bounds === null || bounds === void 0 ? void 0 : bounds.left) || 0 }))));
};
