import * as React from 'react';
import styled from '@emotion/styled';
import { TabsWidget } from '../../widgets/TabsWidget';
import { Switch, matchPath, useLocation, useHistory, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import { Routes } from '../../routes';
import { useAuthStore } from '../../stores/AuthStore';
// @ts-ignore
import avatar from '../../../images/avatar.png';
import { EditableImageWidget } from '../../widgets/editable/EditableImageWidget';
var S;
(function (S) {
    S.Container = styled.div `
    padding-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  `;
    S.UserMenubar = styled.div `
    background: #11151a;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
    S.Wrapper = styled.div `
    width: 1200px;
  `;
    S.HeaderBackground = styled(EditableImageWidget) `
    height: 100%;
    width: 100%;
    position: absolute;
  `;
    S.Header = styled.div `
    position: relative;
    height: 300px;
  `;
    S.ProfileLayer = styled.div `
    position: absolute;
    display: flex;
    justify-content: flex-start;
    height: 100%;
  `;
    S.HeaderLeft = styled.div `
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
  `;
    const AVATAR_SIZE = 250;
    S.Avatar = styled(EditableImageWidget) `
    width: ${AVATAR_SIZE}px;
    height: ${AVATAR_SIZE}px;
    background: #0e1013;
  `;
    S.HeaderName = styled.div `
    padding: 15px 20px;
    min-width: 250px;
    color: white;
    font-size: 30px;
    flex-grow: 1;
    background-color: rgba(17, 21, 26, 0.9);
    font-family: 'forced-square', sans-serif;
  `;
})(S || (S = {}));
export const ProfilePage = (props) => {
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    const useAuth = useAuthStore();
    let key = 'profile';
    if (matchPath(location.pathname, Routes.PROFILE_INVENTORY)) {
        key = 'inventory';
    }
    return (React.createElement(S.Container, null,
        React.createElement(S.Wrapper, null,
            React.createElement(S.Header, null,
                React.createElement(S.HeaderBackground, { image: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%233e1b19' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%236e2520' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%239d3026' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23cd3a2d' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23fd4533' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E" }),
                React.createElement(S.ProfileLayer, null,
                    React.createElement(S.HeaderLeft, null,
                        React.createElement(S.Avatar, { image: useAuth.authenticatedUser.photoURL || avatar }),
                        React.createElement(S.HeaderName, null, useAuth.authenticatedUser.displayName)))),
            React.createElement(S.UserMenubar, null,
                React.createElement(TabsWidget, { tabSelected: key, tabs: [
                        {
                            key: 'profile',
                            label: 'Profile'
                        },
                        {
                            key: 'inventory',
                            label: 'Inventory'
                        }
                    ], onTabSelected: (tab) => {
                        if (tab.key === 'profile') {
                            history.push(generatePath(Routes.PROFILE, {
                                username: params.username
                            }));
                        }
                        else if (tab.key === 'inventory') {
                            history.push(generatePath(Routes.PROFILE_INVENTORY, {
                                username: params.username
                            }));
                        }
                    } })),
            React.createElement(Switch, null))));
};
