import * as React from 'react';
import { styled } from '../theme/theme';
var S;
(function (S) {
    S.Container = styled.div `
    background: ${(p) => p.theme.forms.inputBackground};
    padding: 10px;
  `;
    S.Label = styled.div `
    color: ${(p) => p.theme.forms.labelColor};
    font-size: 14px;
    font-family: 'forced-square', sans-serif;
    margin-bottom: 10px;
    user-select: none;
  `;
    S.Error = styled.div `
    color: ${(p) => p.theme.forms.inputActiveAccent};
    font-size: 14px;
    font-family: 'forced-square', sans-serif;
    margin-bottom: 10px;
    margin-left: 10px;
  `;
    S.Top = styled.div `
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
})(S || (S = {}));
export const InputWrapperWidget = (props) => {
    return (React.createElement(S.Container, { className: props.className },
        React.createElement(S.Top, null,
            React.createElement(S.Label, null, props.label.toUpperCase()),
            props.error ? React.createElement(S.Error, null, props.error.toUpperCase()) : null),
        props.children));
};
