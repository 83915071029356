/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/core';
var S;
(function (S) {
    S.Container = styled.div `
    svg {
      max-width: 100%;
      max-height: 100%;
      opacity: ${(p) => (p.initial ? 0 : 1)};
    }
  `;
})(S || (S = {}));
const gen = (p) => {
    const an = keyframes `
     0% {
      stroke-dashoffset: ${p.length};
    }

    90%{
      stroke-opacity: 1;
    }

    100%{
      stroke-dashoffset: 0;
      stroke-opacity: 0;
    }
  `;
    return css `
    .path-${p.index} {
      ${!p.fill ? `fill: transparent !important` : ''};
      stroke-dasharray: ${p.length};
      stroke-width: ${p.stroke}px;
      animation: ${an} ${p.duration}s ease-out forwards;
    }
  `;
};
export const AnimatedSVGWidget = (props) => {
    const ref = React.createRef();
    const [lengths, setLengths] = React.useState([]);
    const [fill, setFill] = React.useState(false);
    React.useEffect(() => {
        const paths = Array.from(ref.current.querySelectorAll('path'));
        setTimeout(() => {
            paths.forEach((path, index) => {
                path.style.fill = path.style.stroke;
                path.style.transition = 'fill 0.5s';
                setFill(true);
            });
        }, props.duration * 1000 - 500);
        const l = [];
        paths.forEach((path, index) => {
            path.style.stroke = path.style.fill;
            path.classList.add(`path-${index}`);
            l.push(path.getTotalLength());
        });
        setLengths(l);
    }, []);
    const c = css(lengths.map((length, index) => gen({
        duration: props.duration,
        length,
        index,
        stroke: props.strokeThickness || 1,
        fill: fill
    })));
    return (jsx(S.Container, { initial: lengths.length === 0, className: props.className, css: c, ref: ref, dangerouslySetInnerHTML: { __html: props.svgData } }));
};
