import { v4 } from 'uuid';
export class BaseObserver {
    constructor() {
        this.listeners = {};
    }
    iterateListeners(cb) {
        for (let i in this.listeners) {
            cb(this.listeners[i]);
        }
    }
    registerListener(list) {
        const id = v4();
        this.listeners[id] = list;
        return () => {
            delete this.listeners[id];
        };
    }
}
