import * as React from 'react';
import styled from '@emotion/styled';
import { AnimatedLogoWidget } from '../widgets/AnimatedLogoWidget';
import { keyframes } from '@emotion/core';
import { AuthenticatedRedirect } from '../stores/AuthStore';
var S;
(function (S) {
    S.Container = styled.div `
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  `;
    const animation = keyframes `
    0%{
      transform: scale(2);
      opacity: 0;

    }
    100%{
      transform: scale(1);
      opacity: 1;
    }
  `;
    S.Logo = styled(AnimatedLogoWidget) `
    width: 450px;
    margin-top: -100px;
    animation: ${animation} forwards cubic-bezier(0.22, 1, 0.36, 1) 5s;
  `;
})(S || (S = {}));
export const HomePageWidget = (props) => {
    return (React.createElement(AuthenticatedRedirect, null,
        React.createElement(S.Container, null,
            React.createElement(S.Logo, { duration: 5, strokeThickness: 1 }))));
};
