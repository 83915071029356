import * as React from 'react';
import { styled } from '../theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
var S;
(function (S) {
    S.Container = styled.div `
    background: ${(p) => (p.loading ? p.theme.forms.inputActiveAccent : p.theme.forms.inputBackground)};
    padding: 10px 20px;
    cursor: pointer;

    display: inline-block;

    &:hover {
      background: ${(p) => p.theme.forms.inputActiveAccent};
    }
  `;
    S.Label = styled.div `
    font-family: ${(p) => p.theme.forms.inputFont};
    color: ${(p) => p.theme.forms.inputColor};
    font-size: 20px;
  `;
    S.Icon = styled(FontAwesomeIcon) `
    color: white;
  `;
})(S || (S = {}));
export const FormButtonWidget = (props) => {
    return (React.createElement(S.Container, { className: props.className, loading: props.loading, onClick: () => {
            // cant click when loading
            if (props.loading) {
                return;
            }
            props.action();
        } },
        !props.loading ? React.createElement(S.Label, null, props.label) : null,
        props.loading ? React.createElement(S.Icon, { icon: faSpinner, spin: true }) : null));
};
