var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { AuthStore } from './stores/AuthStore';
import { observable } from 'mobx';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
export class System {
    constructor() {
        this.cache = new InMemoryCache();
        this.apolloLink = createHttpLink({
            uri: location.host !== 'mech.cloud' ? 'http://localhost:5001/mech-cloud-28241/us-central1/graphql' : '/graphql'
        });
        this.client = new ApolloClient({
            link: this.apolloLink,
            cache: this.cache
        });
        this.authStore = new AuthStore();
        this.authStore.registerListener({
            authenticated: (event) => {
                // recreate apollo with auth link
                this.client = new ApolloClient({
                    link: event.authLink.concat(this.apolloLink),
                    cache: new InMemoryCache()
                });
            }
        });
    }
    init() {
        this.authStore.init();
    }
}
__decorate([
    observable
], System.prototype, "client", void 0);
export const SystemContext = React.createContext(null);
