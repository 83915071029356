import * as React from 'react';
import { InputWrapperWidget } from './InputWrapperWidget';
import { useField } from 'formik';
import { styled } from '../theme/theme';
var S;
(function (S) {
    const LPAD = 15;
    const BORDER = 3;
    const FOCUS_GROW = 5;
    S.Input = styled.input `
    outline: none;
    border: transparent;
    color: ${(p) => p.theme.forms.inputColor};
    background: ${(p) => p.theme.forms.inputBackground};
    width: 100%;
    font-size: 40px;
    font-family: ${(p) => p.theme.forms.inputFont};
    border-left: solid ${BORDER}px ${(p) => p.theme.forms.inputInactiveAccent};
    padding-left: ${LPAD}px;

    transition: padding-left 0.3s, border-left 0.3s;

    // turn off ios rounding
    -webkit-appearance: none;
    -webkit-border-radius: 0;

    // turn off autocomplete
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${(p) => p.theme.forms.inputBackground} inset !important;
      -webkit-text-fill-color: ${(p) => p.theme.forms.inputColor} !important;
    }

    &:active,
    &:focus {
      border-left: solid ${BORDER + FOCUS_GROW}px ${(p) => p.theme.forms.inputActiveAccent};
      padding-left: ${LPAD - FOCUS_GROW}px;
    }

    &::selection {
      background: ${(p) => p.theme.forms.inputActiveAccent};
    }
  `;
})(S || (S = {}));
export const InputFieldWidget = (props) => {
    const [field, meta] = useField(props.name);
    return (React.createElement(InputWrapperWidget, { className: props.className, label: props.label, error: meta.error },
        React.createElement(S.Input, Object.assign({}, field, (props.input || {})))));
};
