import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BodyWidget } from './widgets/BodyWidget';
import * as firebase from 'firebase';
import { BrowserRouter } from 'react-router-dom';
// @ts-ignore
import font_forcedSquare from '../fonts/forced-square.ttf';
import 'typeface-open-sans';
import { Global, css } from '@emotion/core';
import { System, SystemContext } from './System';
document.addEventListener('DOMContentLoaded', (event) => {
    firebase.initializeApp({
        apiKey: 'AIzaSyBQolrRjd1pxRNUTzkoY4bpaofwMWLb2eo',
        authDomain: 'mech-cloud-28241.firebaseapp.com',
        databaseURL: 'https://mech-cloud-28241.firebaseio.com',
        projectId: 'mech-cloud-28241'
    });
    const system = new System();
    system.init();
    ReactDOM.render(React.createElement(React.Fragment, null,
        React.createElement(Global, { styles: css `
          * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
          }
          html,
          body,
          #application {
            height: 100%;
            overflow: hidden;
          }
          @font-face {
            font-family: 'forced-square';
            src: url(${font_forcedSquare});
          }
        ` }),
        React.createElement(BrowserRouter, { basename: "/" },
            React.createElement(SystemContext.Provider, { value: system },
                React.createElement(BodyWidget, null)))), document.querySelector('#application'));
});
