var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SystemContext } from '../System';
import { useContext } from 'react';
import * as firebase from 'firebase';
import { setContext } from 'apollo-link-context';
import { observable } from 'mobx';
import { BaseObserver } from '../util/BaseObserver';
import { Redirect, generatePath } from 'react-router-dom';
import { Routes } from '../routes';
import { observer } from 'mobx-react';
import * as React from 'react';
const USER_SESSION = 'user';
export class AuthStore extends BaseObserver {
    constructor() {
        super();
        this.authenticatedUser = null;
        firebase.auth().onAuthStateChanged((user) => __awaiter(this, void 0, void 0, function* () {
            if (user) {
                this.updateUser(user);
                const t = yield user.getIdToken(true);
                const authLink = setContext((_, { headers }) => {
                    return {
                        headers: Object.assign(Object.assign({}, headers), { authorization: `Bearer ${t}` })
                    };
                });
                this.iterateListeners((c) => { var _a; return (_a = c.authenticated) === null || _a === void 0 ? void 0 : _a.call(c, {
                    authLink: authLink
                }); });
            }
            // user is signed in, sign out since session expired
            else if (this.authenticatedUser) {
                this.signout();
            }
        }));
    }
    signout() {
        this.authenticatedUser = null;
        window.sessionStorage.removeItem(USER_SESSION);
        firebase.auth().signOut();
        window.location = window.location.origin;
    }
    updateUser(userData) {
        this.authenticatedUser = {
            uid: userData.uid,
            providerId: userData.providerId,
            photoURL: userData.photoURL,
            phoneNumber: userData.phoneNumber,
            email: userData.email,
            displayName: userData.displayName
        };
        window.sessionStorage.setItem(USER_SESSION, JSON.stringify(this.authenticatedUser));
    }
    init() {
        try {
            const user = window.sessionStorage.getItem(USER_SESSION);
            if (!user) {
                return;
            }
            this.updateUser(JSON.parse(user));
        }
        catch (ex) { }
    }
}
__decorate([
    observable
], AuthStore.prototype, "authenticatedUser", void 0);
export const AuthenticatedRedirect = observer((props) => {
    const authStore = useAuthStore();
    if (authStore.authenticatedUser) {
        return React.createElement(Redirect, { to: generatePath(Routes.PROFILE, { username: authStore.authenticatedUser.uid }) });
    }
    return props.children;
});
export const useAuthStore = () => {
    const system = useContext(SystemContext);
    return system.authStore;
};
