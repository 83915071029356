import * as React from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';
import * as useImage from 'use-image';
import { useState, useEffect } from 'react';
const layer1 = require('../../images/background-layer-gray.png');
const layer2 = require('../../images/background-layer-gray-fill.png');
const layer3 = require('../../images/background-layer-orange.png');
var S;
(function (S) {
    const animation = keyframes `
    0%{
      transform: rotateX(-20deg) rotateY(-20deg);

    }
    100%{
      transform: rotateX(20deg) rotateY(20deg);
    }
  `;
    S.Container = styled.div `
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
    animation: ${animation} 20s ease-in-out alternate infinite;
    animation-play-state: ${(p) => (p.animate ? 'paused' : 'running')};
  `;
    const common = css `
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  `;
    S.Layer1 = styled.div `
      background: url('${layer1.default}');
      transition: transform 4s;
      transform: translateZ(${(p) => (p.static ? 0 : -50)}px);
      ${common};
  `;
    S.Layer2 = styled.div `
      background: url('${layer2.default}');
      ${common};
  `;
    S.Layer3 = styled.div `
      background: url('${layer3.default}');
      transition: transform 4s;
      transform: translateZ(${(p) => (p.static ? 0 : -0)}px);
      ${common};
  `;
    S.Wrapper = styled.div `
    perspective: 1000px;
    width: 100%;
    height: 100%;
    transition: transform 20s, opacity 1s;
    opacity: ${(p) => (p.initial ? 0 : 1)};
    transform: scale(${(p) => (p.initial ? 1 : 1.5)});
  `;
})(S || (S = {}));
export const BackgroundWidget = (props) => {
    // @ts-ignore
    const [image1, status1] = useImage(layer1.default);
    // @ts-ignore
    const [image2, status2] = useImage(layer2.default);
    // @ts-ignore
    const [image3, status3] = useImage(layer3.default);
    const [initial, setInitial] = useState(true);
    const [ready, setReady] = useState(false);
    useEffect(() => {
        if ([status1, status2, status3].indexOf('loading') === -1) {
            setReady(true);
            setTimeout(() => {
                setInitial(false);
            }, 100);
        }
    }, [status1, status2, status3]);
    if (!ready) {
        return null;
    }
    return (React.createElement(S.Wrapper, { initial: initial, className: props.className },
        React.createElement(S.Container, { animate: props.animate },
            React.createElement(S.Layer1, { static: initial || props.static }),
            React.createElement(S.Layer2, null),
            React.createElement(S.Layer3, { static: initial || props.static }))));
};
