import * as React from 'react';
import styled from '@emotion/styled';
import { Route, withRouter } from 'react-router-dom';
import { SignupPage } from '../pages/SignupPage';
import { VerifyPage } from '../pages/VerifyPage';
import { BackgroundWidget } from './BackgroundWidget';
import { HeaderWidget } from './HeaderWidget';
import { HomePageWidget } from '../pages/HomePageWidget';
import { Routes } from '../routes';
import { ThemeProvider } from 'emotion-theming';
import { DarkTheme } from './theme/theme';
import { AnimatedSwitch } from 'react-router-transition';
import { SigninPage } from '../pages/SigninPage';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage';
import { observer } from 'mobx-react';
import { ProfilePage } from '../pages/user/ProfilePage';
import { useState, useEffect } from 'react';
var S;
(function (S) {
    S.Animated = styled(AnimatedSwitch) `
    position: relative;
    height: 100%;
    width: 100%;
    > div {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  `;
    S.Content = styled.div `
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: backdrop-filter 2s, background 2s;
    backdrop-filter: ${(p) => (p.blur ? 'blur(5px)' : 'none')};
    background: ${(p) => (p.blur ? `rgba(0, 0, 0, 0.4)` : 'transparent')};
  `;
    S.Top = styled.div `
    height: 3px;
    background: #393e4a;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
  `;
    S.TopOrangeLeft = styled.div `
    background: #ff4e31;
    width: 200px;
  `;
    S.TopOrangeRight = styled.div `
    background: #ff4e31;
    width: 200px;
  `;
})(S || (S = {}));
export const BodyWidget = withRouter(observer((props) => {
    const isHomepage = props.location.pathname === '/';
    const [blur, setBlur] = useState(!isHomepage);
    const [timeout, setTimer] = useState(null);
    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout);
        }
        setTimer(setTimeout(() => {
            const isHomepage = props.location.pathname === '/';
            if (!isHomepage && !blur) {
                setBlur(true);
            }
            else if (isHomepage && blur) {
                setBlur(false);
            }
        }, 500));
    }, [isHomepage, blur]);
    // we need to map the `scale` prop we define below
    // to the transform style property
    function mapStyles(styles) {
        return {
            opacity: styles.opacity,
            transform: `scale(${styles.scale})`
        };
    }
    // child matches will...
    const bounceTransition = {
        // start in a transparent, upscaled state
        atEnter: {
            opacity: 0,
            scale: 1.2
        },
        // leave in a transparent, downscaled state
        atLeave: {
            opacity: 0,
            scale: 0.8
        },
        // and rest at an opaque, normally-scaled state
        atActive: {
            opacity: 1,
            scale: 1
        }
    };
    return (React.createElement(ThemeProvider, { theme: DarkTheme },
        React.createElement(BackgroundWidget, { animate: !isHomepage, static: blur }),
        React.createElement(S.Content, { blur: blur },
            React.createElement(S.Top, null,
                React.createElement(S.TopOrangeLeft, null),
                React.createElement(S.TopOrangeRight, null)),
            React.createElement(HeaderWidget, { backdrop: blur }),
            React.createElement(S.Animated, { atEnter: bounceTransition.atEnter, atLeave: bounceTransition.atLeave, atActive: bounceTransition.atActive, mapStyles: mapStyles },
                React.createElement(Route, { path: Routes.HOMEPAGE, component: HomePageWidget, exact: true }),
                React.createElement(Route, { path: Routes.PROFILE, component: ProfilePage }),
                React.createElement(Route, { path: Routes.SIGNUP, component: SignupPage }),
                React.createElement(Route, { path: Routes.SIGNIN, component: SigninPage }),
                React.createElement(Route, { path: Routes.VERIFY, component: VerifyPage }),
                React.createElement(Route, { path: Routes.FORGOT_PASSWORD, component: ForgotPasswordPage })))));
}));
