var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import styled from '@emotion/styled';
import { Form, Formik } from 'formik';
import * as firebase from 'firebase';
import { InputFieldWidget } from '../widgets/forms/InputFieldWidget';
import { FormButtonWidget } from '../widgets/forms/FormButtonWidget';
import { useHistory } from 'react-router-dom';
import { Routes } from '../routes';
import { AuthenticatedRedirect } from '../stores/AuthStore';
var S;
(function (S) {
    S.Wrapper = styled.div `
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  `;
    S.Container = styled.div `
    display: flex;
  `;
    S.Input = styled(InputFieldWidget) `
    margin-bottom: 10px;
  `;
    S.Left = styled.div `
    padding: 60px;
    background: #0e1013;
    user-select: none;
  `;
    S.Right = styled.div `
    padding: 60px;
    background: #0b0d0f;
  `;
    S.LeftLarge = styled.div `
    font-family: 'forced-square', sans-serif;
    font-size: 60px;
    margin-top: 10px;
    color: white;
  `;
    S.Buttons = styled.div `
    display: flex;
  `;
    S.Button = styled(FormButtonWidget) `
    margin-right: 5px;
  `;
})(S || (S = {}));
export const SigninPage = () => {
    const history = useHistory();
    return (React.createElement(AuthenticatedRedirect, null,
        React.createElement(S.Wrapper, null,
            React.createElement(S.Container, null,
                React.createElement(S.Left, null,
                    React.createElement(S.LeftLarge, null, "Welcome Back")),
                React.createElement(S.Right, null,
                    React.createElement(Formik, { initialValues: {
                            email: '',
                            password: ''
                        }, onSubmit: (values, o) => __awaiter(void 0, void 0, void 0, function* () {
                            try {
                                yield firebase.auth().signInWithEmailAndPassword(values.email, values.password);
                            }
                            catch (ex) {
                                if (ex.code === 'auth/invalid-email') {
                                    o.setFieldError('email', 'Invalid email');
                                }
                                else if (ex.code === 'auth/user-disabled') {
                                    o.setFieldError('email', 'Account locked');
                                }
                                else if (ex.code === 'auth/user-not-found') {
                                    o.setFieldError('email', 'Account not found');
                                }
                                else if (ex.code === 'auth/wrong-password') {
                                    o.setFieldError('password', 'Wrong password');
                                }
                            }
                        }) }, ({ submitForm, isSubmitting }) => {
                        return (React.createElement(Form, { autoComplete: "off" },
                            React.createElement(S.Input, { label: "Email", name: "email" }),
                            React.createElement(S.Input, { input: { type: 'password' }, label: "Password", name: "password" }),
                            React.createElement(S.Buttons, null,
                                React.createElement(S.Button, { loading: isSubmitting, action: submitForm, label: 'sign in' }),
                                React.createElement(S.Button, { action: () => {
                                        history.push(Routes.FORGOT_PASSWORD);
                                    }, label: 'forgot password' }))));
                    }))))));
};
