import s from '@emotion/styled';
export const DarkTheme = {
    forms: {
        inputFont: "'forced-square', sans-serif",
        buttonFont: "'forced-square', sans-serif",
        labelColor: 'rgb(145,145,132)',
        inputBackground: '#08090a',
        inputColor: 'white',
        inputActiveAccent: '#ff4e31',
        inputInactiveAccent: 'rgb(74,74,68)'
    }
};
export const styled = s;
