import * as React from 'react';
import styled from '@emotion/styled';
import Cropper from 'react-easy-crop';
import { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import ReactSlider from 'react-slider';
var S;
(function (S) {
    S.Container = styled.div `
    overflow: hidden;
    position: relative;
  `;
    S.CropperWidget = styled(Cropper) `
    width: 100%;
    height: 100%;
  `;
    S.Zoom = styled.div `
    background: rgba(0, 0, 0, 0.8);
    padding: 7px;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 150px;
    border-radius: 15px;
  `;
    S.StyledSlider = styled(ReactSlider) `
    width: 100%;
    height: 10px;
  `;
    const THUMB_SIZE = 15;
    const StyledThumb = styled.div `
    height: ${THUMB_SIZE}px;
    width: ${THUMB_SIZE}px;
    background-color: white;
    border-radius: 50%;
    cursor: grab;
    top: 50%;
    transform: translateY(-50%);
    &:focus {
      outline: none;
    }
  `;
    const StyledTrack = styled.div `
    top: 0;
    bottom: 0;
    background: ${(props) => (props.index === 1 ? 'rgba(255,255,255,0.15)' : 'rgba(255,255,255,0.7)')};
    border-radius: 999px;
  `;
    S.Track = (props, state) => React.createElement(StyledTrack, Object.assign({}, props, { index: state.index }));
    S.Thumb = (props, state) => React.createElement(StyledThumb, Object.assign({}, props));
})(S || (S = {}));
const CONTAINER_HEIGHT = 300;
export const EditableImageWidget = (props) => {
    const [crop, onCropChange] = useState({ x: 0, y: 0 });
    const [zoom, onZoomChange] = useState(1);
    const [size, setSize] = useState({ width: 0, height: 0 });
    const sizeRef = useRef(size);
    const ref = useRef();
    useEffect(() => {
        const observer = new ResizeObserver((elements) => {
            if (!_.isEqual(sizeRef.current, elements[0].contentRect)) {
                setSize(elements[0].contentRect);
            }
        });
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, []);
    return (React.createElement(S.Container, { ref: ref, className: props.className },
        React.createElement(S.CropperWidget, { image: props.image, crop: crop, restrictPosition: true, cropSize: {
                width: size.width,
                height: size.height
            }, style: {
                cropAreaStyle: {
                    border: 'none'
                }
            }, zoom: zoom, showGrid: false, onCropChange: onCropChange, onZoomChange: (zoom) => {
                if (zoom >= 1) {
                    onZoomChange(zoom);
                }
            } }),
        React.createElement(S.Zoom, null,
            React.createElement(S.StyledSlider, { min: 1, max: 4, value: zoom, step: 0.01, onChange: (value) => {
                    onZoomChange(value);
                }, renderTrack: S.Track, renderThumb: S.Thumb }))));
};
