var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import styled from '@emotion/styled';
import { Form, Formik } from 'formik';
import * as firebase from 'firebase';
import { InputFieldWidget } from '../widgets/forms/InputFieldWidget';
import { FormButtonWidget } from '../widgets/forms/FormButtonWidget';
import * as _ from 'lodash';
import { AuthenticatedRedirect } from '../stores/AuthStore';
var S;
(function (S) {
    S.Wrapper = styled.div `
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  `;
    S.Container = styled.div `
    display: flex;
  `;
    S.Input = styled(InputFieldWidget) `
    margin-bottom: 10px;
  `;
    S.Left = styled.div `
    padding: 60px;
    background: #0e1013;
    user-select: none;
  `;
    S.Right = styled.div `
    padding: 60px;
    background: #0b0d0f;
  `;
    S.LeftSmall = styled.div `
    font-family: 'forced-square', sans-serif;
    font-size: 30px;
    margin-bottom: 5px;
    color: white;
  `;
    S.LeftLarge = styled.div `
    font-family: 'forced-square', sans-serif;
    font-size: 60px;
    margin-top: 10px;
    color: white;
  `;
})(S || (S = {}));
export const SignupPage = () => {
    return (React.createElement(AuthenticatedRedirect, null,
        React.createElement(S.Wrapper, null,
            React.createElement(S.Container, null,
                React.createElement(S.Left, null,
                    React.createElement(S.LeftSmall, null, "_Pilot"),
                    React.createElement(S.LeftSmall, null, "_Engineer"),
                    React.createElement(S.LeftSmall, null, "_Fan"),
                    React.createElement(S.LeftLarge, null, "Welcome.")),
                React.createElement(S.Right, null,
                    React.createElement(Formik, { initialValues: {
                            displayName: '',
                            email: '',
                            password: '',
                            passwordAgain: ''
                        }, validateOnChange: false, validateOnBlur: false, validate: (values) => {
                            const ob = {};
                            _.keys(values).forEach((key) => {
                                if (!values[key] || _.trim(values[key]) === '') {
                                    ob[key] = 'Required';
                                }
                            });
                            if (values.password !== values.passwordAgain) {
                                ob['passwordAgain'] = 'Passwords need to match';
                            }
                            return ob;
                        }, onSubmit: (values, o) => __awaiter(void 0, void 0, void 0, function* () {
                            try {
                                const user = yield firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
                                yield user.user.updateProfile({
                                    displayName: values.displayName
                                });
                                yield user.user.sendEmailVerification({
                                    url: 'http://localhost:9001/verify'
                                });
                            }
                            catch (error) {
                                if (error.code == 'auth/weak-password') {
                                    o.setFieldError('password', 'Password is too weak');
                                }
                                else if (error.code == 'auth/invalid-email') {
                                    o.setFieldError('email', 'Email not valid');
                                }
                                else if (error.code == 'auth/email-already-in-use') {
                                    o.setFieldError('email', 'Email already in use');
                                }
                            }
                        }) }, ({ submitForm, isSubmitting }) => {
                        return (React.createElement(Form, { autoComplete: "off" },
                            React.createElement(S.Input, { label: "Display Name", name: "displayName" }),
                            React.createElement(S.Input, { label: "Email", name: "email" }),
                            React.createElement(S.Input, { input: { type: 'password' }, label: "Password", name: "password" }),
                            React.createElement(S.Input, { input: { type: 'password' }, label: "Password Verify", name: "passwordAgain" }),
                            React.createElement(FormButtonWidget, { loading: isSubmitting, action: submitForm, label: 'sign up' })));
                    }))))));
};
