export var Routes;
(function (Routes) {
    Routes["HOMEPAGE"] = "/";
    Routes["PROFILE"] = "/user/:username";
    Routes["PROFILE_INVENTORY"] = "/user/:username/inventory";
    Routes["SIGNUP"] = "/signup";
    Routes["SIGNIN"] = "/signin";
    Routes["VERIFY"] = "/verify";
    Routes["FORGOT_PASSWORD"] = "/forgot";
})(Routes || (Routes = {}));
